<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block mr-50" />
      <!-- <b-link
        class="mr-50"
        to="/shop/wishlist"
      >
        <feather-icon
          :badge="parseInt($store.state['app-ecommerce'].wishlistItemsCount, 10)"
          class="text-body"
          badgeClasses="badge-info"
          icon="HeartIcon"
          size="21"
        />
      </b-link> -->
      <!-- <cart-dropdown /> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div v-if="user">
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ user.FIO }}
              </p>
              <span class="user-status">{{ user.descr || '' }}</span>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :text="avatarText(user.FIO)"
              class="badge-minimal"
              badge-variant="success"
            />
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          to="/profile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import Bookmarks from '@core/layouts/components/app-navbar/components/Bookmarks.vue'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem,
  BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import CartDropdown from '@core/components/e-commerce/e-commerce-cart-dropdown/CartDropdown.vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    Bookmarks,
    // CartDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    user() {
      return this.$store.getters['app/user']
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('app/logout')
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>
